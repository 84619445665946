import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions('Report', [
      'ActionSetReportsTotal',
      'ActionSetReports',
      'ActionSetReportSelect',
      'ActionSetFilterReports',
      'ActionSetDialogReports'
    ]),

    async getClickReport(page, perPage) {
      let query = '';
      console.log(this.filterReports);

      if (!perPage) query += 'per_page=10%26';
      else query += perPage;

      if (!page) query += 'page=1';
      else query += page;

      if (!this.filterReports) query += '';
      else query += `%26${this.filterReports}`;
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=report&path=/api/get/report/click_report?' + query
        );
        if (status === 200) {
          if (data.data.length > 0) {
            this.ActionSetReports(data.data);
          } else {
            this.ActionSetReports(Object.values(data.data));
          }
        } else this.ActionSetReports([]);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getConsolidatedClickReport(page, perPage) {
      let query = '';

      if (!perPage) query += 'per_page=10%26';
      else query += perPage;

      if (!page) query += 'page=1';
      else query += page;

      if (!this.filterReports) query += '';
      else query += `%26${this.filterReports}`;
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=report&path=/api/report/click_report?' + query);
        if (status === 200) {
          if (data.data.length > 0) {
            this.ActionSetReports(data.data);
          } else {
            this.ActionSetReports(Object.values(data.data));
          }
        } else this.ActionSetReports([]);
        this.ActionSetPagination(data);
      } catch (error) {
        this.errorNotify(error.response.data.data[0]);
      } finally {
        this.onLoading(false);
      }
    },

    async getClickReportAffiliate(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}%26`;

      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=REPORT&path=/api/get/report/affiliate_click_report?${query}`
        );
        // if (data && data.data.length > 0 && status === 200) this.ActionSetReports(data.data);
        // else this.ActionSetReports();

        if (data.data && status === 200) {
          if (data.data.length > 0) {
            this.ActionSetReports(data.data);
            this.ActionSetReportsTotal(data.report_total);
          } else {
            this.ActionSetReports(Object.values(data.data));
          }
        } else this.ActionSetReports(data);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getConsolidatedClickReportAffiliate(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}%26`;

      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=REPORT&path=/api/report/affiliate_click_report/?${query}`
        );

        if (data.data && status === 200) {
          if (data.data.length > 0) {
            this.ActionSetReports(data.data);
            this.ActionSetReportsTotal(data.report_total);
          } else {
            this.ActionSetReports(Object.values(data.data));
          }
        } else this.ActionSetReports(data);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getClickReportAdvertiser(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}`;

      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=REPORT&path=/api/old/report/advertiser_click_report?${query}`
        );
        if (status === 200) {
          this.ActionSetReports(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getConsolidatedClickReportAdvertiser(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}`;

      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=REPORT&path=/api/report/advertiser_click_report?${query}`
        );
        if (status === 200) {
          this.ActionSetReports(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCaptureReport(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}%26`;

      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=REPORT&path=/api/report/captacao_report?' + query);
        if (status === 200) this.ActionSetReports(data.data);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getConversionReport(page, perPage) {
      let query;
      if (!this.filterReports) query = '';
      else query = `${this.filterReports}%26`;
      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=REPORT&path=/api/get/report/transaction_report?user_id=${this.user.user_id}%26${query}`
        );
        if (data && data.data.length > 0 && status === 200) {
          this.ActionSetReports(data.data);
          this.ActionSetReportsTotal(data.report_total);
        } else this.ActionSetReports([]);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getConversionReportAffiliate(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}%26`;

      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      console.log(query);

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=REPORT&path=/api/get/report/affiliate_transaction_report?' + query
        );
        if (data && data.data.length > 0 && status === 200) {
          this.ActionSetReports(data.data);
          this.ActionSetReportsTotal(data.report_total);
        } else this.ActionSetReports([]);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getConversionReportAdvertiser(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}`;

      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=REPORT&path=/api/old/report/advertiser_transaction_report?' + query
        );
        if (data && data.data.length > 0 && status === 200) this.ActionSetReports(data.data);
        else this.ActionSetReports([]);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCreativeReport(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}`;

      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=REPORT&path=/api/report/creative_report?' + query);

        if (data && status === 200) this.ActionSetReports(data.data);
        else this.ActionSetReports([]);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getAllCreativeReport() {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}`;

      query += '%26page=1';
      query += '%26per_page=9999999999';

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=REPORT&path=/api/report/creative_report?' + query);

        if (data && status === 200) return data.data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCreativeReportAdvertiser(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}`;

      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=REPORT&path=/api/report/creative_report_advertiser?' + query
        );

        if (data && status === 200) this.ActionSetReports(data.data);
        else this.ActionSetReports([]);
        this.ActionSetPagination(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async createExtraMargin(campaignId, value, userEmail, emailSent) {
      this.onLoading(true);
      try {
        return await this.$http.post('/v2/redirect?app=REPORT&path=/api/transaction/history/create_extra_margin', {
          params: {
            data: {
              campaign_id: campaignId,
              value: value,
              user_email: userEmail,
              email_sent_at: emailSent
            }
          }
        });
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getLatestEmailSent(campaignId) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=REPORT&path=/api/transaction/history/emails-sent/' + campaignId
        );

        if (data && status === 200) {
          return data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  computed: {
    ...mapGetters('Report', ['reportsTotal', 'reports', 'reportSelect', 'filterReports', 'dialogReports']),

    columns() {
      return [
        {
          name: 'id_creative',
          label: 'ID',
          align: 'left',
          field: 'id_creative',
          sortable: true
        },
        {
          name: 'creative_type',
          label: 'Tipo de Criativo',
          align: 'left',
          field: 'creative_type',
          sortable: true
        },
        {
          name: 'resolution',
          label: 'Tamanho do Banner de Imagem',
          align: 'left',
          field: 'resolution',
          sortable: true
        },
        {
          name: 'prints',
          label: 'Qtd de Impressões',
          align: 'left',
          field: 'prints',
          sortable: true
        },
        {
          name: 'clicks',
          label: 'Qtd de Cliques',
          align: 'left',
          field: 'clicks',
          sortable: true
        },
        {
          name: 'uniqueClicks',
          label: 'Qtd de Cliques Únicos',
          align: 'left',
          field: 'uniqueClicks',
          sortable: true
        },
        {
          name: 'transactions',
          label: 'Qtd de conversões',
          align: 'left',
          field: 'transactions',
          sortable: true
        },
        {
          name: 'pending_total_amount',
          label: 'Valor da Comissão Full Pendente',
          align: 'left',
          field: 'pending_total_amount',
          sortable: true
        },
        {
          name: 'pending_margin',
          label: 'Valor da Comissão Afilio Pendente',
          align: 'left',
          field: 'pending_margin',
          sortable: true
        },
        {
          name: 'pending_cost',
          label: 'Valor da Comissão Afiliado Pendente',
          align: 'left',
          field: 'pending_cost',
          sortable: true
        },
        {
          name: 'approved_total_amount',
          label: 'Valor da Comissão Full Aprovado',
          align: 'left',
          field: 'approved_total_amount',
          sortable: true
        },
        {
          name: 'approved_margin',
          label: 'Valor da Comissão Afilio Aprovado',
          align: 'left',
          field: 'approved_margin',
          sortable: true
        },
        {
          name: 'approved_cost',
          label: 'Valor da Comissão Afiliado Aprovado',
          align: 'left',
          field: 'approved_cost',
          sortable: true
        }
      ];
    },

    conversionColumns() {
      return [
        {
          name: 'campaign_id',
          label: this.$t('campaign_id'),
          align: 'left',
          field: 'campaign_id',
          sortable: true
        },
        {
          name: 'campaign_name',
          label: this.$t('campaign_name'),
          align: 'left',
          field: 'campaign_name',
          sortable: true
        },
        {
          name: 'advertiser_id',
          label: 'ID Advertiser',
          align: 'left',
          field: 'advertiser_id',
          sortable: true
        },
        {
          name: 'site_id',
          label: 'ID Canal',
          align: 'left',
          field: 'site_id',
          sortable: true
        },
        {
          name: 'site_name',
          label: this.$t('channel_name'),
          align: 'left',
          field: 'site_name',
          sortable: true
        },
        {
          name: 'affiliate_id',
          label: this.$t('affiliate_id'),
          align: 'left',
          field: 'affiliate_id',
          sortable: true
        },
        {
          name: 'affiliate_name',
          label: this.$t('affiliate_name'),
          align: 'left',
          field: 'affiliate_name',
          sortable: true
        },
        {
          name: 'transaction_id',
          label: this.$t('transaction_id'),
          align: 'left',
          field: 'transaction_id',
          sortable: true
        },
        {
          name: 'order_id',
          label: 'Tra ID',
          align: 'left',
          field: 'order_id',
          sortable: true
        },
        {
          name: 'creative_id',
          label: this.$t('creative_id'),
          align: 'left',
          field: 'creative_id',
          sortable: true
        },
        {
          name: 'creative_type',
          label: 'Tipo de Criativo',
          align: 'left',
          field: 'creative_type',
          sortable: true
        },
        {
          name: 'comission_id',
          label: 'Act_ID',
          align: 'left',
          field: 'comission_id',
          sortable: true
        },
        {
          name: 'comission_type',
          label: this.$t('type'),
          align: 'left',
          field: 'comission_type',
          sortable: true
        },
        {
          name: 'transaction_amount',
          label: this.$t('transaction_amount'),
          align: 'left',
          field: 'transaction_amount',
          sortable: true
        },
        {
          name: 'full_commission_amount',
          label: this.$t('full_commision_amount'),
          align: 'left',
          field: 'full_commission_amount',
          sortable: true
        },
        {
          name: 'affiliate_commission_cost',
          label: this.$t('affiliate_commission_cost'),
          align: 'left',
          field: 'affiliate_commission_cost',
          sortable: true
        },
        {
          name: 'afilio_commition_margin',
          label: 'Margem (Comissão Afilio)',
          align: 'left',
          field: 'afilio_commition_margin',
          sortable: true
        },
        {
          name: 'xtrasParams1',
          label: this.$t('affiliate_parameter') + ' Xtra1',
          align: 'left',
          field: 'xtrasParams1',
          sortable: true
        },
        {
          name: 'xtrasParams2',
          label: this.$t('affiliate_parameter') + ' Xtra2',
          align: 'left',
          field: 'xtrasParams2',
          sortable: true
        },
        {
          name: 'xtrasParams3',
          label: this.$t('affiliate_parameter') + ' Xtra3',
          align: 'left',
          field: 'xtrasParams3',
          sortable: true
        },
        {
          name: 'xtrasParams4',
          label: this.$t('affiliate_parameter') + ' Xtra4',
          align: 'left',
          field: 'xtrasParams4',
          sortable: true
        },
        {
          name: 'xtrasParams5',
          label: this.$t('affiliate_parameter') + ' Xtra5',
          align: 'left',
          field: 'xtrasParams5',
          sortable: true
        },
        {
          name: 'ad_xtra1',
          label: this.$t('advertiser_parameter') + ' Xtra1',
          align: 'left',
          field: 'ad_xtra1',
          sortable: true
        },
        {
          name: 'ad_xtra2',
          label: this.$t('advertiser_parameter') + ' Xtra2',
          align: 'left',
          field: 'ad_xtra2',
          sortable: true
        },
        {
          name: 'ad_xtra3',
          label: this.$t('advertiser_parameter') + ' Xtra3',
          align: 'left',
          field: 'ad_xtra3',
          sortable: true
        },
        {
          name: 'ad_xtra4',
          label: this.$t('advertiser_parameter') + ' Xtra4',
          align: 'left',
          field: 'ad_xtra4',
          sortable: true
        },
        {
          name: 'ad_xtra5',
          label: this.$t('advertiser_parameter') + ' Xtra5',
          align: 'left',
          field: 'ad_xtra5',
          sortable: true
        },
        {
          name: 'click_datetime',
          label: this.$t('click_datetime'),
          align: 'left',
          field: 'click_datetime',
          sortable: true
        },
        {
          name: 'transaction_datetime',
          label: this.$t('transaction_datetime'),
          align: 'left',
          field: 'transaction_datetime',
          sortable: true
        },
        {
          name: 'validation_datetime',
          label: this.$t('validation_datetime'),
          align: 'left',
          field: 'validation_datetime',
          sortable: true
        },
        {
          name: 'time_diff',
          label: 'Date Dif',
          align: 'left',
          field: 'time_diff',
          sortable: true
        },
        {
          name: 'ip',
          label: 'IP',
          align: 'left',
          field: 'ip',
          sortable: true
        },
        {
          name: 'validation_status',
          label: 'Status',
          align: 'left',
          field: 'status_name',
          sortable: true
          // format: (status) => this.labelStatus(status),
        },
        {
          name: 'cj_transactions_id',
          label: 'Transaction ID CJ',
          align: 'left',
          field: 'cj_transactions_id',
          sortable: true
        },
        {
          name: 'bookingDate',
          label: 'Data de Reserva',
          align: 'left',
          field: 'bookingDate',
          sortable: true
        },
        {
          name: 'bookingStatus',
          label: 'Status de Reserva',
          align: 'left',
          field: 'bookingStatus',
          sortable: true
        },
        {
          name: 'couponType',
          label: 'Tipo de Cupom',
          align: 'left',
          field: 'couponType',
          sortable: true
        },
        {
          name: 'couponDiscount',
          label: 'Desconto Cupom',
          align: 'left',
          field: 'couponDiscount',
          sortable: true
        },
        {
          name: 'destCity',
          label: 'Cidade Destino',
          align: 'left',
          field: 'destCity',
          sortable: true
        },
        {
          name: 'destCountry',
          label: 'País Destino',
          align: 'left',
          field: 'destCountry',
          sortable: true
        },
        {
          name: 'endDateTime',
          label: 'Data e horário de término',
          align: 'left',
          field: 'endDateTime',
          sortable: true
        },
        {
          name: 'platformId',
          label: 'ID da plataforma',
          align: 'left',
          field: 'platformId',
          sortable: true
        },
        {
          name: 'source_url',
          label: 'URL Resp',
          align: 'left',
          field: 'source_url',
          sortable: true
        },
        {
          name: 'first_url',
          label: 'URL de Redirecionamento',
          align: 'left',
          field: 'first_url',
          sortable: true
        }
      ];
    }
  }
};
